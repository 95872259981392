@charset "UTF-8";
body, html {
    background-color: #3B3C3E;
    margin: 0;
    font-size: 16px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 1.5em;
    scroll-behavior: smooth;
    min-height: calc(100vh - 118px);
}

body.inner-page, html.inner-page {
    background: #171717;
}

body#login-page, html#login-page {
    overflow: hidden;
}

body#login-page nav, html#login-page nav {
    position: absolute;
    display: block;
    background: transparent;
    width: 100%;
}

/* body .privacy-setting, html .privacy-setting {
  min-height: calc(100vh - 118px);
} */

 #v-pills-client img{
     width: 100%;
 }

#v-pills-client p span{
    color: white !important;
}

header {
    z-index: 3;
}
.privacy-setting-box {
    min-height: calc(100vh - 118px);
}

.profile-sec {
    min-height: calc(100vh - 118px);
}

body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.comp-logo {
    width: 250px;
    padding-top: 10px;
}


p {
    margin-top: 0;
    margin-bottom: 10px;
}

.subtitle {
    color: #FF6161;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
}

.bor-radius-10 {
    border-radius: 10px !important;
}

h1, h2, h3, h4, h5 {
    font-family: 'Poppins', sans-serif;
}

h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 73px;
    line-height: 1em;
    color: #EE3F45;
}

@media only screen and (max-width: 991px) {
    h1 {
        font-size: 50px;
    }
    .create-player .form-check{
        margin-right: 0px !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
}

h1 span {
    color: #fff;
}

h1 span.red {
    font-size: 30px;
    font-weight: 600;
    color: #cf61ff;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 800;
}

.form-label {
    margin-bottom: 0px;
}

.position-relative {
    position: relative;
}

.play-icon {
    width: 25px;
}

.serv-float-left {
    float: left;
}

.serv-float-right {
    float: right;
}

section {
    padding: 80px 0;
    overflow: hidden;
}

section#banner {
    padding: 150px 0px 120px 0px;
}

.purple {
    color: #cf61ff;
}

.red {
    color: #FF6161;
}

.btn {
    border-style: hidden;
}

.btn-player {
    padding: 0px;
    margin: 6px 0px;
}

.btn-check:focus + .btn, .btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.banner-description {
    padding: 25px 0;
}

.banner-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 39px;
}

.btn-bundle {
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.btn-bundle.primary-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#FF8D4B), to(#FF4139));
    background: linear-gradient(90deg, #FF8D4B, #FF4139);
}

.btn-bundle.primary-gradient:hover {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, right top, from(#FF5E5E), color-stop(51%, #FF8D4B), to(#FF8D4B)) !important;
    background-image: linear-gradient(to right, #FF5E5E 0%, #FF8D4B 51%, #FF8D4B 100%) !important;
}

.btn-bundle.blue {
    background: -webkit-gradient(linear, left top, right top, from(#007bff), to(#007bff));
    background: linear-gradient(90deg, #007bff, #007bff);
}

.btn-bundle.blue:hover {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, right top, from(#007bff), color-stop(51%, #007bff), to(#FF8D4B)) !important;
    background-image: linear-gradient(to right, #007bff 0%, #007bff 51%, #FF8D4B 100%) !important;
}

.btn-bundle.red {
    background: -webkit-gradient(linear, left top, right top, from(#db0505), to(#FF4139));
    background: linear-gradient(90deg, #db0505, #FF4139);
}

.btn-bundle.red:hover {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, right top, from(#db0505), color-stop(51%, #db0505), to(#FF8D4B)) !important;
    background-image: linear-gradient(to right, #db0505 0%, #db0505 51%, #FF8D4B 100%) !important;
}

.btn-bundle.orange {
    background: -webkit-gradient(linear, left top, right top, from(#db6405), to(#db6405));
    background: linear-gradient(90deg, #db6405, #db6405);
}

.btn-bundle.orange:hover {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, right top, from(#db6405), color-stop(51%, #db6405), to(#FF8D4B)) !important;
    background-image: linear-gradient(to right, #db6405 0%, #db6405 51%, #FF8D4B 100%) !important;
}

.watch-btn {
    font-weight: 800;
    font-size: 16px;
}

.watch-btn img {
    width: 15px;
    position: relative;
    left: -5px;
    top: -2px;
}

.view-all {
    font-weight: 400;
    font-size: 16px;
}

.view-all img {
    position: relative;
    right: -12px;
    top: -2px;
}

.btn-text {
    font-weight: 400;
    font-size: 16px;
}

.create-playlist-btn {
    font-weight: 400;
    font-size: 16px;
    margin: 0px 15px !important;
}

.btn-gradient-one {
    background-image: -webkit-gradient(linear, left top, right top, from(#F16BBA), color-stop(51%, #9C6DEA), to(#9C6DEA)) !important;
    background-image: linear-gradient(to right, #F16BBA 0%, #9C6DEA 51%, #9C6DEA 100%) !important;
    -webkit-transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.btn-gradient-one:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(#9C6DEA), color-stop(51%, #FF5E5E), to(#FF5E5E)) !important;
    background-image: linear-gradient(to right, #9C6DEA 0%, #FF5E5E 51%, #FF5E5E 100%) !important;
}

.btn-gradient-two {
    background-image: -webkit-gradient(linear, left top, right top, from(#9947A3), color-stop(51%, #F16BBA), to(#F16BBA)) !important;
    background-image: linear-gradient(to right, #9947A3 0%, #F16BBA 51%, #F16BBA 100%) !important;
    -webkit-transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.btn-gradient-two:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(#F16BBA), color-stop(51%, #B4003B), to(#B4003B)) !important;
    background-image: linear-gradient(to right, #F16BBA 0%, #B4003B 51%, #B4003B 100%) !important;
}

.btn-gradient-three {
    background-image: -webkit-gradient(linear, left top, right top, from(#EA004E), color-stop(51%, #AA0059), to(#AA0059)) !important;
    background-image: linear-gradient(to right, #EA004E 0%, #AA0059 51%, #AA0059 100%) !important;
    -webkit-transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.btn-gradient-three:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(#AA0059), color-stop(51%, #EA004E), to(#EA004E)) !important;
    background-image: linear-gradient(to right, #AA0059 0%, #EA004E 51%, #EA004E 100%) !important;
}

.user-row .dropdown-menu a:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8F4B), color-stop(51%, #FF3D38), to(#FF3D38));
    background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
    color: #fff;
}

.user-row .dropdown-menu a span {
    margin-right: 5px;
}

.notifications-row .dropdown-menu a:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8F4B), color-stop(51%, #FF3D38), to(#FF3D38));
    background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
    color: #fff;
}

.video-dropdown .dropdown-menu a:hover {
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8F4B), color-stop(51%, #FF3D38), to(#FF3D38));
    background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
    color: #fff;
}

.gradiant-button-pb {
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8F4B), color-stop(51%, #FF3D38), to(#FF3D38));
    background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
}

.gradiant-button-pb {
    margin: 10px;
    padding: 12px 30px;
    text-align: center;
    /*
    text-transform: uppercase;
    */
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 50px;
    display: block;
    border-style: hidden;
}

.gradiant-button-pb:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.gradiant-button-pb img {
    margin-right: 5px;
}

.btn-transparent {
    background: transparent;
}

.btn-purple {
    border: 1px solid #dd29fe;
    color: #fff;
    border-radius: 10px;
    padding: 20px 50px;
}

.btn-st {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    padding: 0px;
    width: 400px;
    position: relative;
    background: #fff;
    border-radius: 10px;
}
.price-range-slider .cover-gif .btn-submit {
    display: block;
}

.price-range-slider .apply-btn .btn-submit {
    display: none;
}
.editor-page-pills-box {
    display: flex;
}
.edit-videojs-row-box {
    position: absolute;
    top: 5px;
}
.editor-page-btn a{
    margin: 3px;
}

/* .editor-page-pills ul#pills-tab li button {
    padding: 5px;
} */

@media only screen and (max-width: 991px) {
    .btn-st {
        width: 100%;
    }
    .page-tabs.editor-page-pills .nav-link{
        padding: 0px 10px;
    }

    .nav-item .user-row {
        margin-right: 0px !important;
        padding: 0.5rem 0rem ;
    }

    .nav-item .user-row button{
        padding: 0px !important;
    }
   
}

@media only screen and (max-width: 1341px) {
    .page-tabs.editor-page-pills .nav-link{
        padding: 0px 10px;
    }
}

.btn-st:hover {
    color: #fff;
}

.btn-st:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-st .right-arrow {
    position: relative;
    top: 4px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50px;
}

.btn-st img {
    padding: 0 10px;
}

.serv-box {
    padding: 10px 8px;
}

.f-left {
    float: left !important;
}

.f-right {
    float: right !important;
}

.revert {
    display: revert;
}

.highlight.red {
    color: #FF6161;
}

.text-medium {
    font-weight: 500;
}

.btn-notifications {
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8D4B), color-stop(51%, #FF8D4B), to(#FF4139)) !important;
    background-image: linear-gradient(to right, #FF8D4B 0%, #FF8D4B 51%, #FF4139 100%) !important;
}

.btn-notifications {
    border-radius: 50px;
    font-size: 15px;
    color: #fff !important;
}

.btn-notifications img {
    width: 20px;
    margin-right: 10px;
}

.btn-notifications:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.btn-details {
    display: block;
    text-align: left;
    margin-left: 10px;
    font-size: 16px;
    padding: 10px 0;
}

.btn-details:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.btn-user {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #fff;
    /* padding-right: 25px; */
}

.btn-user:hover {
    color: #fff;
}

.btn-user img {
    width: 40px;
}

.btn-user.dropdown-toggle::after {
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 0;
}
.btn-user::after {
    display: none;
}

.btn-user:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.btn-user-name {
    padding: 0;
    margin: 0;
}

nav {
    background-image: url("./images/header-bg.png");
    background-size: cover;
    background-color: transparent;
}

.nav-transparent {
    background-image: none !important;
    background-size: cover;
    background-color: transparent;
}

/*NAV*/
.navbar {
    position: relative;
    /* z-index: 0; */
    padding: 5px 0;
}

.navbar-light .navbar-toggler {
    background-color: #FF6161;
}

.navbar-light .navbar-toggler-icon {
    background-color: #FF6161;
}

.navbar-light .navbar-nav .nav-link {
    color: #fff !important;
    position: relative;
    margin-right: 30px;
    font-size: 15px;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #FF6161 !important;
}

.navbar-light .navbar-nav .nav-link.active:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #FF6161;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 991px) {
    .navbar-light .navbar-nav .nav-link.active:after {
        margin-left: 0;
    }
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #FF6161 !important;
}

 .nav-item button:hover {
    color: #FF6161 !important;
}

.upgrades-sec .nav-item button:hover {
    color: #fff  !important;
}

.upgrades-sec .nav-item button:active {
    color: #fff !important;
}


header .nav-item button:hover::before {
    content: "";
    position: absolute;
    width: 100;
    height: 4px;
    background: #FF6161;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

header .navbar-light .navbar-nav .nav-link:hover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #FF6161;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

#navbar_top .dropdown-toggle::after{ display: none !important;}

.btn-user{ position: relative;}
.btn-user:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #FF6161;
    bottom: 0;
    left: 0;
    /* right: 0; */
    margin-left: auto;
    margin-right: auto;
}

.nav-item div{ margin: 0 !important;}
/* div#navbarSupportedContent .btn-group .btn-user{
    padding: 0 !important;
} */

@media only screen and (max-width: 1200px) {
    .btn-duration-submit {
        padding: 10px 22px;
    }

    .price-range-slider .cover-gif .btn-submit {
        display: none;
    }

    .price-range-slider .apply-btn .btn-submit {
        display: block;
    }
    .color-picker {
        width: 15px !important;
        height: 15px !important;
    }
}

@media only screen and (max-width: 991px) {
    .navbar-light .navbar-nav .nav-link:hover:after {
        margin-left: 0;
    }
    .btn-duration-submit {
        width: 100%;
        padding: 10px 30px;
    }

    .nav-item div.dropdown-menu.show{
        margin-right: 0px;
    }
    .color-picker {
        width: 30px !important;
        height: 30px !important;
    }
    .time-seconds{
        margin-top: 10px;
    }
    .time-seconds .time-seconds-box {
        margin-bottom: 15px;
    }
    .time-seconds label.small{
        top: -25px;
    }
}

.navbar-light .navbar-nav .nav-link:active {
    color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:active:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #FF6161;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

div#navbarSupportedContent .btn-group {
    display: block;
}

div#navbarSupportedContent .btn-group .btn-user {
    font-size: 15px;
    padding: 0.5rem 1rem;
    
}

.welcome {
    color: #ff5b3f;
    font-weight: 500;
    font-size: 12px;
    padding-bottom: 0;
    margin-bottom: 0px;
}

.form-select{
    background-image: url("./images/down-arrow-icon.png");
    background-size: 16px 16px;
}

/*BANNER*/
#banner {
    /*background-image: url("./images/banner-bg.png");*/
    background-size: cover;
    position: relative;
}

#banner > .container {
    position: relative;
    z-index: 1;
}

#banner canvas {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#351b4b), to(#08060e));
    background-image: linear-gradient(180deg, #351b4b 0%, #08060e 100%);
    z-index: 0;
}

#bg-video iframe {
    position: absolute;
    min-height: 100vh;
    min-width: 177.77vh;
    width: 100vw;
    height: 56.25vw;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.next1 {
    height: 100%;
    width: 90px;
    padding: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.more-about-services {
    float: right;
}

.more-about-services-list p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 5px 25px;
    color: #fff;
    display: inline-block;
    line-height: 2.5em;
}

.more-about-services-list:first-child .btn-st .next {
    background: #FF5E5E;
}

.more-about-services-list:nth-child(2) .btn-st .next {
    background: #B4003B;
}

.more-about-services-list:nth-child(3) .btn-st .next {
    background: #EB004D;
}

.serv-box {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.serv-icon {
    width: 60px;
}

.serv-icon img {
    width: 80px;
}

#my-video-projects .card {
    background: #DD3636;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(#DD3636), to(#771515));
    background: linear-gradient(to bottom, #DD3636, #771515);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: 3px solid #fff;
    padding: 10px;
    border-radius: 15px;
}

#my-video-projects h2 {
    font-weight: 400;
    font-size: 40px !important;
}

#my-video-projects .view-all {
    float: right;
}

.my-video-projects .card .my-video-projects-box-details {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 20px 0px;
}

.video-play {
    background: #fff;
    padding: 6px 12px;
    border-radius: 50px;
}

.video-play img {
    width: 15px;
    height: 15px;
}

.video-title {
    width: 100%;
    background: #FF4B4B;
    padding: 7px 15px;
    border-radius: 50px;
    margin-left: 0px;
    font-weight: 600;
}

.video-title p {
    font-size: 14px;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.my-video-projects .card-img-top {
    border-radius: 15px;
    height: 235px;
    width: 100%;
}

.my-video-projects .card-box {
    position: relative;
    width: 100%;
    height: 235px;
    background: orange;
    border-radius: 15px;
    overflow: hidden;
}

.my-video-projects .card-box-edit-more {
    color: #fff;
    padding: 0px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    margin-top: 25px;
    
}

.my-video-projects .card-box-edit-more li {
    display: inline-block;
    padding: 20px 10px;
}

.my-video-projects .card-box-edit-more li a {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
}

.my-video-projects .card-box-edit-more li a:hover {
    color: #FF6161;
}

.my-video-projects .card-box-edit-more li a:hover i {
    color: #FF6161;
}

.my-video-projects .card-box-edit-more li a i {
    font-size: 30px;
}

.my-video-projects .card-box-date {
    text-align: left;
    margin-top: 10px;
}

.my-video-projects .card-box-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    background: #0000005e;
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    -webkit-clip-path: circle(90.3% at 100% 0);
    clip-path: circle(90.3% at 100% 0);
    opacity: 0;
    z-index: 0;
}

.my-video-projects .card:hover .card-box-overlay {
    opacity: 1;
    -webkit-transition: .8s ease-in-out;
    transition: .8s ease-in-out;
    -webkit-clip-path: circle(141.4% at 100% 0);
    clip-path: circle(141.4% at 100% 0);
}

.my-video-projects .card:hover .overlay {
    display: block;
}

.video-dropdown {
    position: absolute;
    right: 0px;
    top: 14px;
}

.video-dropdown button:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
    border: 1px transparent;
}

.video-dropdown {
    border-radius: 50px;
    background: none !important;
}

.video-dropdown .btn-secondary {
    background: none !important;
}

.date p.date {
    position: absolute;
    width: 100%;
    background: #0000007a;
    height: 100%;
    top: 0;
    border-radius: 15px;
    padding: 10px 10px;
}

.services img {
    width: 800px;
}

.services-box {
    position: relative;
    display: block;
    height: 100%;
    padding: 20px 0;
    top: 5em;
}

.services-desciption {
    margin: 0;
    height: 100%;
    display: block;
    width: 100%;
}

.laptop-img {
    margin-left: 125px;
}

.laptop-img-2 {
    margin-left: -250px;
}

#join-now {
    background-image: url("./images/join-now-bg.jfif");
    background-size: cover;
}

.help-banner{
    background-image: url("./images/join-now-bg.jfif");
    background-size: cover;
}

#join-now h2 {
    font-size: 30px;
}

.join-now-img img {
    width: 600px;
    background-color: #F11653;
    padding: 20px;
    border-radius: 20px;
}

footer {
    background: #000;
    padding: 20px;
    margin-bottom: 0;
}

footer p {
    margin-bottom: 0;
}

footer .footer-menu {
    padding: 0;
    text-align: left;
    margin-bottom: 0rem;
}

footer .footer-menu li {
    display: inline-block;
    margin-right: 35px;
}

footer .footer-menu li:last-child {
    margin-right: 0px;
}

footer .footer-menu li a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
}
footer .footer-menu li a:hover {
    text-decoration: none;
    color: #FF6161;
 }

footer .copyright {
    text-align: center;
}

footer .copyright a {
    color: #fff;
    text-decoration: none;
}

footer .copyright a:hover {
    color: #FF6161;
}

footer .reelapps-img {
    text-align: right;
}

footer .vineasx-img {
    text-align: right;
}

footer .vineasx-img img {
    width: 100px;
}

.footer-mid-light {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.box {
    position: relative;
    background: transparent;
    width: 100%;
    padding: 10px 0;
}

.box-header {
    position: relative;
    width: 100%;
}

.box-tools {
    position: relative;
    top: 22px;
}

.dropzone-wrapper {
    border: 2px dashed #8F8F8F;
    color: #8F8F8F;
    position: relative;
    height: 120px;
    background: #171717;
    border-radius: 10px;
}

.dropzone-desc {
    position: relative;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    font-size: 16px;
}

.dropzone,
.dropzone:focus {
    position: absolute;
    outline: none !important;
    width: 100%;
    height: 130px;
    cursor: pointer;
    opacity: 0;
    top: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
    background: #323232;
}

.preview-zone {
    text-align: center;
}

.preview-zone .box {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
}

.thumbnail-form {
    background: #303030;
    padding: 20px;
    border-radius: 15px;
}

.thumbnail-form .form-group {
    background: #303030;
}

.thumbnail-form .box-body {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    overflow: hidden;
}

.thumbnail-form .box-body img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.thumbnail-form label {
    font-size: 14px;
}

.thumbnail-form .btn-remove {
    font-size: 14px;
    font-weight: 500;
    color: #6E6E6E;
    padding: 0;
}

.thumbnail-form .btn-remove i {
    color: #fff;
    font-size: 40px;
    position: relative;
    top: 9px;
    left: 7px;
}

.upload-form .form-group {
    background: #303030;
}

.upload-form .box-body {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    overflow: hidden;
}

.upload-form .box-body img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.upload-form label {
    font-size: 14px;
}

.upload-form .btn-remove {
    font-size: 14px;
    font-weight: 500;
    color: #6E6E6E;
    padding: 0;
}

.upload-form .btn-remove i {
    color: #fff;
    font-size: 40px;
    position: relative;
    top: 9px;
    left: 7px;
}

.upload-form.select-template-box .box-tools {
    position: relative;
    right: 0px;
    top: 0px;
}

.upload-form .box-tools {
    position: relative;
    right: 10px;
    top: 22px;
}

.login-sec {
    min-height: calc(100vh - 118px);
    background-image: url("./images/login-bg.jfif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 100%;
}

.login-sec h1 {
    font-size: 55px;
}

.login-sec p {
    font-size: 18px;
    font-weight: 300;
    color: #c1c1c1;
    width: 80%;
    margin: 0 auto;
}

.login-sec h5 {
    font-size: 29px;
}

.login-box {
    padding: 0 0 30px;
    min-height: calc(100vh - 151px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.login-form {
    padding: 50px;
    background-color: #101010;
    border-radius: 25px;
    border: 1px solid #FF6161;
}

.login-form input {
    background-color: transparent !important;
    border-color: #FF6161;
    border-radius: 10px !important;
    color: #ffff;
    padding: 15px;
}

.login-form .form-label {
    font-size: 20px;
    margin-bottom: 15px;
}

p.forgot-account {
    font-size: 14px !important;
    text-align: center;
}

p.forgot-account a {
    text-decoration: none;
    color: #FF6161;
}

p.forgot-account a:hover {
    opacity: .8;
}

.btn-submit {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    margin: 0 auto;
}

.login-form .form-control:focus {
    background-color: #fff !important;
    color: #000;
    border-color: #FF6161;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem #FF6161;
    box-shadow: 0 0 0 0.25rem #FF6161;
}

.form-control:focus{
    border: 1px solid transparent;
    border: 1px solid transparent;
}

.video-projects-sec {
    min-height: calc(100vh - 118px);
    padding: 0 0 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.project-button-search .input-group {
    background: #fff;
    border-radius: 50px;
    padding: 5px;
}

.project-button-search .input-group-text {
    background: transparent;
    height: 100%;
    border: 0px;
}

.project-button-search input {
    background: #fff;
    border-radius: 50px !important;
    border: 0px solid;
}

.project-button-search input:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.project-count {
    position: relative;
    color: #a2a2a2;
    font-size: 25px;
    font-weight: 200;
    margin-left: 30px !important;
}

.project-count:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: #a2a2a2;
    top: 3px;
    left: -15px;
}

.integration-box li {
    max-width: 114px;
    width: 100%;
    background-color: #000;
    text-align: center;
    padding: 20px;
    margin: 0 12px;
    border-radius: 8px;
    display: block;
    color: #fff !important;
    cursor: pointer;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: all .4s;
    transition: all .4s;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
}

.integration-box li:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(#FF8D4B), to(#FF4139));
    background: linear-gradient(180deg, #FF8D4B, #FF4139);
    -webkit-box-shadow: 0 0 20px 0 #ff8649, 0 0 20px 0 #ff4c3b;
    box-shadow: 0 0 20px 0 #ff8649, 0 0 20px 0 #ff4c3b;
}

.integration-box li .img-box {
    min-height: 56px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.integration-box li img {
    width: 60px;
}

.integration-box li a {
    color: #fff !important;
    text-decoration: none;
}

.integration-box li a i {
    font-size: 35px;
}

.connection-img img {
    width: 60px;
}

.connection-box {
    background: #000;
    padding: 20px;
    border-radius: 10px;
    scroll-margin: 100px 0 0 0;
}

.connection-box:nth-child(6) .connection-img img {
    width: 100px;
}

.connection-box:nth-child(7) .connection-img img {
    width: 100px;
}

.help-banner-content h3 {
    font-size: 42px;
    font-weight: 300;
}

.help-banner {
    background-color: #000;
}

.help-banner-content h3 {
    font-size: 42px;
}

.help-search .input-group {
    background: #363c3d;
    border-radius: 50px;
    padding: 5px;
}

.help-search .input-group i {
    color: #fff;
}

.help-search .input-group-text {
    background: transparent;
    height: 100%;
    border: 0px;
}

.help-search input {
    background: #363c3d;
    border-radius: 50px !important;
    border: 0px solid;
    color: #fff;
}

.help-search input:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
    background: #363c3d;
    color: #fff;
    z-index: 1 !important;
}

.help-banner-img {
    position: relative;
    -webkit-animation: mymove 3s;
    animation: mymove 3s;
    -webkit-animation-iteration-count: 5;
    animation-iteration-count: 5;
}

.help-banner-img img {
    width: 500px;
}

.important-topics a {
    text-decoration: none;
}

.important-topics-content {
    background: -webkit-gradient(linear, left top, left bottom, from(#FF8D4B), to(#FF4139));
    background: linear-gradient(180deg, #FF8D4B, #FF4139);
    padding: 40px;
    border-radius: 15px;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    height: 247px;
}

.important-topics span {
    width: 100px;
    height: 100px;
    background-color: #FF6161;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px auto 20px;
    border: 2px solid #fff;
}

.important-topics span img {
    max-width: 50%;
}

.popular-video-tutorials {
    background: #000;
}

.player-box {
    background: -webkit-gradient(linear, left top, left bottom, from(#FF8D4B), to(#FF4139));
    background: linear-gradient(180deg, #FF8D4B, #FF4139);
    padding: 10px;
    border-radius: 10px;
}

.player-box-title {
    text-align: left;
    color: #fff;
    font-size: 14px;
}

.player-box-title i {
    margin-right: 5px;
}

.customer-content h6 {
    color: #FF6161;
}

.customer-content h5 {
    font-size: 28px;
}

.customer-content button {
    margin: 10px auto;
}

#editor-page {
    background-color: #333333;
    position: relative;
    z-index: 0;
    overflow: hidden;
    min-height: calc(100vh - 118px);
}

#editor-page-btn {
    width: 100%;
    text-align: right;
    position: relative;
    z-index: 1;
}

.page-tabs.editor-page-pills {
    background: #000;
    padding: 20px 20px;
    margin-bottom: 50px;
}

.page-tabs.editor-page-pills li {
    padding: 10px 0px;
}

.page-tabs.editor-page-pills li:last-child button {
    border-right: 0px;
}

.page-tabs.editor-page-pills .nav-link {
    color: #989898 !important;
    background: transparent;
    border-right: 1px solid #989898;
    border-radius: 0px;
    padding: 0px 15px;
    font-size: 12px;
}

.page-tabs.editor-page-pills .nav-link.active {
    color: #FF9B4E !important;
}

.border-radius-circle {
    border-radius: 100px !important;
}

.bg-sec-box {
    background: #303030;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}

.bg-sec-box small {
    color: #8A8A8A;
    font-size: 14px;
    padding: 10px 0;
}

.bg-sec-box textarea {
    background: #000;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
}

.bg-sec-box select {
    background-color: #303030;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
}

.color-picker {
    border-radius: 2px;
    width: 30px;
    height: 30px;
}

.color-picker.white {
    background: #fff;
}

.color-picker.red {
    background: #FF0000;
}

.color-picker.light-orange {
    background: #FF9B4E;
}

.color-picker.light-green {
    background: #00B23B;
}

.color-picker.pink {
    background: #FC9CFF;
}

.color-picker.brown {
    background: #6E5400;
}

.color-picker-box {
    background-color: #0C0C0C;
    border: 2px solid #707070;
    padding: 10px;
    border-radius: 8px;
}

.color-picker-box p {
    font-size: 11px;
    margin-bottom: 0;
    color: #787878;
}

.color-picker-box i {
    top: 4px !important;
}

.color-picker-box-text a {
    position: relative;
    top: -3px;
    left: 5px;
}

.btn-xs-small {
    padding: 8px ​20px !important;
}

.btn-small {
    padding: 12px 30px !important;
}

.btn-gradient-orange {
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8F4B), color-stop(51%, #FF3D38), to(#FF3D38));
    background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
}

.btn-gradient-orange {
    margin: 10px;
    text-align: center;
    /*
    text-transform: uppercase;
    */
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    border-style: hidden;
    text-decoration: none;
}

.btn-gradient-orange:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.btn-gradient-purple {
    background-image: -webkit-gradient(linear, left top, right top, from(#A319D2), color-stop(51%, #DC2AA0), to(#DC2AA0));
    background-image: linear-gradient(to right, #A319D2 0%, #DC2AA0 51%, #DC2AA0 100%);
}

.btn-gradient-purple {
    margin: 10px;
    text-align: center;
    /*
    text-transform: uppercase;
    */
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    border-style: hidden;
    text-decoration: none;
}

.btn-gradient-purple:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

#pills-tabContent {
    height: 100%;
    width: 100%;
}

#pills-tabContent.img-gallery {
    background: #191919;
    padding: 20px 10px;
}

#pills-tabContent.tab-editor {
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    min-height: calc(100vh - 118px);
}

#pills-tabContent.tab-editor::-webkit-scrollbar {
    width: 1em;
}

#pills-tabContent.tab-editor::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#pills-tabContent.tab-editor::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

#pills-tabContent h5 {
    color: #FF9B4E;
    font-size: 16px;
    margin-bottom: 15px;
}

.thumbnail-content {
    background: #191919;
}

.thumbnail-content h5 {
    font-size: 16px;
}

.modal-content {
    background-color: #000;
}

.modal-dialog {
    max-width: 1000px !important;
}

.btn-close {
    background: none;
    color: #fff;
    height: 30px;
    width: 30px;
    opacity: 1;
}

.btn-close:hover {
    color: #FF6161;
}

.btn-close:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.btn-close i {
    font-size: 30px;
}

.giphy-box {
    padding: 20px;
}

.giphy-box .giphy-img {
    padding: 0;
    margin: 0;
    background: transparent;
}

.giphy-box .giphy-img img {
    padding: 0;
    margin: 0;
    background: #707070;
    border: 1px solid #707070;
}

.img-library {
    padding: 50px 10px;
}

.img-library-box {
    border-radius: 20px;
    overflow: hidden;
}

.img-library #pills-tab {
    background: #000;
    border-radius: 50px;
    overflow: hidden;
}

.search-bg-white {
    background: #fff;
    border-radius: 8px;
}

.search-bg-white .input-group-text {
    background: transparent;
    border: 0px;
    height: 100%;
}

.search-bg-white .input-group-text i {
    color: #FF9B4E;
    font-size: 22px;
}

.search-bg-white .form-control {
    border: 0px solid;
    border-radius: 8px;
    padding: 13px 5px;
}

.search-bg-white .form-control:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.img-library-box {
    height: auto;
}

.img-library-box #pills-tab .nav-link {
    color: #fff;
    padding: 12px 15px;
    width: 100%;
    border-radius: 0px;
}

.img-library-box #pills-tab .nav-link.active {
    color: #fff !important;
    border-bottom: 0px solid #FF9B4E;
    background: #FF6161;
    border-radius: 0px;
}

.img-library-box #pills-tab li {
    border-right: 1px solid #707070;
    width: 33%;
}

.img-library-box #pills-tab li:last-child {
    border-right: 0px;
}

.img-thumbnails {
    overflow-x: hidden;
}

.img-thumbnails::-webkit-scrollbar {
    width: 1em;
}

.img-thumbnails::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.img-thumbnails::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.img-library ul li:first-child button {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}

.img-library ul li:last-child button {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}

.price-range {
    background: #303030;
    border-radius: 20px;
    padding: 30px 20px;
}

.price-range label {
    font-size: 14px;
    color: #989898;
}

.video-player {
    text-align: center;
    padding: 50px 20px;
}

.video-player img {
    width: 700px;
    text-align: center;
}

.social-content-box input, .bg-sec-box input {
    background: #000;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
}

.social-content-box input::-webkit-input-placeholder, .bg-sec-box input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #787878;
}

.social-content-box input::-moz-placeholder, .bg-sec-box input::-moz-placeholder {
    /* Firefox 19+ */
    color: #787878;
}

.social-content-box input:-ms-input-placeholder, .bg-sec-box input:-ms-input-placeholder {
    /* IE 10+ */
    color: #787878;
}

.social-content-box input:-moz-placeholder, .bg-sec-box input:-moz-placeholder {
    /* Firefox 18- */
    color: #787878;
}

.social-content-box input:focus, .bg-sec-box input:focus {
    background: #000;
    color: #fff;
    border-color: transparent;
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.social-content-box textarea, .bg-sec-box textarea {
    background: #000;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
}

.social-content-box textarea::-webkit-input-placeholder, .bg-sec-box textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #787878;
}

.social-content-box textarea::-moz-placeholder, .bg-sec-box textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #787878;
}

.social-content-box textarea:-ms-input-placeholder, .bg-sec-box textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #787878;
}

.social-content-box textarea:-moz-placeholder, .bg-sec-box textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #787878;
}

.social-content-box textarea:focus, .bg-sec-box textarea:focus {
    background: #000;
    color: #fff;
    border-color: #fff;
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.save-button-cta {
    color: #818181;
    font-size: 14px;
    margin: 0;
}

.social-share-box {
    background: #303030;
    border: 2px solid #464646;
    padding: 10px;
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.social-share-box-left img {
    width: 28px;
}

.social-share-box-left p {
    margin-bottom: 0;
}

.social-share-box p {
    font-size: 14px;
}

.tooltip-box-left-auto {
    left: 0px;
}

.tooltip-box-left-auto i {
    color: #757575;
    font-size: 20px;
    position: relative;
    top: 1px;
}

.tooltip-box i {
    color: #757575;
    font-size: 20px;
    position: relative;
    top: 1px;
    left: 3px;
}

.ui-widget.ui-widget-content {
    border: 1px solid #5B5B5B !important;
}

.header-close button {
    position: relative;
    top: -14px;
    left: 14px;
    height: auto;
    width: auto;
}

.header-close button i {
    font-size: 20px;
}

.bg-sec-box select {
    background-color: #303030;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
    width: 100%;
}

.bg-sec-box select:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
    border-color: #fff !important;
}

.bg-sec-box .custom-select {
    background-color: #303030 !important;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #707070 !important;
    border-radius: 10px !important;
    width: 100%;
}

.bg-sec-box .custom-select:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
    border-color: #fff !important;
}

.custom-select {
    background-color: #2A2A2A !important;
    border-radius: 5px !important;
    padding: 5px 10px;
}

select option {
    background-color: #000;
}
.custom-file-input::-webkit-file-upload-button {
    background: #FF4B2B;
    color: white;
    padding: 8px 25px;
    font-size: 10px;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-color: #FF4B2B;
    transition: all 0.15s ease;
    letter-spacing: 1px;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    line-height: 1.5;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
}

.cs-7 {
    padding: 7px;
}

.related-videos .box-tools {
    top: 0 !important;
    right: 0 !important;
}

.remove-box .box-tools {
    top: 0 !important;
    right: 0 !important;
}

.bg-sec-list li {
    display: inline-block;
    padding: 0px 5px;
    margin-bottom: 10px;
}

.bg-sec-list li img {
    width: 60px;
}

.bg-sec-box input {
    background-color: #000;
    color: #fff;
    margin-bottom: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
}

.time-seconds {
    background: #000;
    border-radius: 20px;
    padding: 10px 20px;
}

.time-seconds-box {
    position: relative;
}

.time-seconds label.small {
    font-size: 10px;
    color: #B5B5B5;
    display: block;
    position: absolute;
    top: -22px;
}

.customization-color-picker .color-picker-box {
    padding: 3px 6px;
    border-radius: 0px;
}

.customization-color-picker .color-picker-box-colors ul {
    padding: 0;
    margin: 0;
}

.customization-color-picker .color-picker-box-colors ul li {
    list-style: none;
    margin-right: 1px;
    display: inline-block;
}

.customization-color-picker .color-picker-box-colors .color-pick {
    height: 30px;
    width: 30px;
    background: transparent;
    border-radius: 2px;
}

.customization-color-picker input {
    border-radius: 0px;
    margin-bottom: 0;
}

.customization-color-picker p.small {
    text-align: center;
    font-size: 12px;
    color: #7D7D7D;
}

.select-template-img {
    margin-bottom: 10px;
}

.select-template-img img {
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 10px;
}

.customization-content-box .color-picker-box {
    padding: 3px 6px;
    border-radius: 0px;
}

.customization-content-box .play-button-list, .customization-content-box .controls-button-list {
    padding: 0;
    margin: 0 auto;
    text-align: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    margin-bottom: 0px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #707070;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #00BF85;
}

input:focus + .slider {
    -webkit-box-shadow: 0 0 1px #00BF85;
    box-shadow: 0 0 1px #00BF85;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/*--- /.price-range-slider ---*/
.price-range-slider {
    width: 100%;
    background: #303030;
    border-radius: 20px;
    padding: 30px 20px;
}

.price-range-slider label {
    font-size: 14px;
    color: #989898;
    margin-bottom: 20px;
}

.price-range-slider .range-value {
    margin: 0;
}

.price-range-slider .range-value input {
    width: 100%;
    background: none;
    color: #fff;
    font-size: 16px;
    font-weight: initial;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    margin: 0px 0px 20px 0;
}

.price-range-slider .range-bar {
    border: none;
    background: #5B5B5B;
    height: 5px;
    width: 96%;
    margin-left: 8px;
}

.price-range-slider .range-bar .ui-slider-range {
    background: #FF9B4E;
}

.price-range-slider .range-bar .ui-slider-handle {
    border: none;
    border-radius: 25px;
    background: #FF9B4E;
    border: 2px solid #fff;
    height: 17px;
    width: 17px;
    top: -0.4em;
    cursor: pointer;
}

.price-range-slider .range-bar .ui-slider-handle + span {
    background: #FF9B4E;
}

/*--- /.price-range-slider ---*/
.user-img {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
}

.user-img img {
    width: 100px;
    height: 100px;
}

.personal-details-box-form {
    background-color: #000;
    padding: 50px;
    border-radius: 30px;
}

.personal-details-box h6 {
    font-size: 20px;
}

.personal-details-box .form-group {
    background-color: #1e1e1e;
    color: #fff;
    padding: 5px 20px;
    border-radius: 10px;
    border: 1px solid #707070;
}

.personal-details-box .form-group input {
    background-color: transparent !important;
    border: none;
    padding: 0;
}

.personal-details-box .form-group input:focus {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
    border: 0px !important;
}

.personal-details-box .form-text {
    color: #ffff !important;
}

.personal-details-box .form-control {
    color: #fff !important;
    font-weight: 200;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

.personal-details-box option {
    background-color: #000;
    height: 20px;
    width: 20px;
}

.manage-data-box {
    background: #000;
    padding: 30px;
    border-radius: 15px;
}

.manage-data-box-content {
    padding: 30px 0;
    border-bottom: 1px solid #707070;
}

.manage-data-box-content a {
    text-decoration: none;
    color: #fff;
}

.manage-data-box-content a:hover {
    color: #FF6161;
}

.manage-data-box-content:last-child {
    border: 0px !important;
}

.manage-data-box-content-table .action-btn a {
    margin-right: 8px;
    color: #fff;
    text-decoration: none;
}

.manage-data-box-content-table .action-btn a i {
    font-size: 20px;
}

.manage-data-box-content-table .action-btn a.delete-icon {
    color: #FF6161;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* .upgrades-sec {
    min-height: calc(98vh - 118px);
} */

.traning-sec {
    padding-top: 0 !important;
    min-height: calc(98vh - 118px);
}

.upgrades-sec #pills-tab {
    background: #000;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
}

.upgrades-sec .nav-pills .nav-link.active, .upgrades-sec .nav-pills .show > .nav-link {
    background: -webkit-gradient(linear, left top, right top, from(#FF8D4B), to(#FF4139));
    background: linear-gradient(90deg, #FF8D4B, #FF4139);
    color: #fff;
    border-radius: 0px;
}

.upgrades-sec .nav-link:focus, .upgrades-sec .nav-link:hover {
    background: -webkit-gradient(linear, left top, right top, from(#FF8D4B), to(#FF4139));
    background: linear-gradient(90deg, #FF8D4B, #FF4139);
    color: #fff;
    border-radius: 0px;
}

.upgrades-sec li {
    width: 100%;
    display: block;
}

.upgrades-sec li button {
    width: 100%;
    text-align: left;
    color: #fff;
    padding: 12px 24px;
    border: 0px;
}

.videojs-row-box {
    position: relative;
    /* background: pink; */
    width: 100%;
    top: 0;
    height: 100%;
    display: inline;
}
.videojs-row-box > div {
    width: 135px;
    /* background: yellow; */
    display: inline-block;
    /* display: contents !important; */
}

.videoj-container {
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 5px;
}
.upgrades-sec-content-box {
    background-color: #000;
    padding: 30px 30px;
}

.upgrades-sec-content-box .card {
    background-color: #171717;
    border-radius: 10px;
    overflow: hidden;
    color: #000;
}

.upgrades-sec-content-box .card-header {
    margin: 0;
}

.upgrades-sec-content-box .card-body {
    color: #fff;
    padding: 30px;
}

.upgrades-sec .thumbnail-form {
    background: transparent;
    padding: 20px 20px 0px;
    border-radius: 0px;
}

.upgrades-sec .thumbnail-form .form-group {
    background: transparent;
}

.upgrades-sec .thumbnail-form .form-group input {
    background: #363839;
}

.upgrade-box-content {
    background-color: #171717;
    border-radius: 10px;
    overflow: hidden;
}

.upgrade-para a {
    text-decoration: none;
}

.move-1 {
    -webkit-animation: move1 5s infinite;
    animation: move1 5s infinite;
}

@-webkit-keyframes move1 {
    0% {
        -webkit-transform: rotate(-1deg) translate(-2px, -2px);
        transform: rotate(-1deg) translate(-2px, -2px);
    }
    50% {
        -webkit-transform: rotate(1deg) translate(2px, 2px);
        transform: rotate(1deg) translate(2px, 2px);
    }
    to {
        -webkit-transform: rotate(-1deg) translate(-2px, -2px);
        transform: rotate(-1deg) translate(-2px, -2px);
    }
}

/*MEDIA CSS*/
@media only screen and (max-width: 992px) {
    .navbar-light .navbar-nav .nav-link {
        margin-right: 0px !important;
        text-align: right;
    }

    #navbarSupportedContent .btn-group {
        display: block;
        text-align: right;
    }

    .navbar-nav {
        margin-top: 0rem;
    }

    .more-about-services {
        width: 100%;
        float: left;
        margin-top: 30px;
    }

    .laptop-img-2 {
        margin-left: -500px;
    }

    .notifications-row .dropdown-menu {
        right: 0;
        left: auto;
    }

    .user-row .dropdown-menu {
        right: 0;
        left: auto;
    }
    .videoj-container{
        z-index: 0;
    }
    .project-header {
        margin-bottom: 20px;
    }
    .project-button{
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .revert {
        display: inline-block !important;
    }

    .join-now-img img {
        margin-bottom: 20px;
    }

    #menu-show{
        left: 0 !important;
    }

    .personal-details-box-form{
        padding: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .upgrade-content.tutorial img {
        margin-bottom: 10px;
    }
    .help-banner-img{
        display: none;
    }
    section {
        padding: 50px 0;
    }

    .services img {
        width: 100%;
    }

    .laptop-img {
        margin-left: 0;
    }

    .laptop-img-2 {
        margin-left: 0;
    }

    .services-desciption {
        position: static;
        -webkit-transform: none;
        transform: none;
    }

    .more-about-services {
        margin-top: 50px;
        float: left;
    }

    .more-about-services {
        width: 100%;
    }

    #say-goodbye .row, #sit-back .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-flow: column-reverse;
        flex-flow: column-reverse;
    }

    .btn-st {
        width: 100%;
        color: #fff !important;
        border-radius: 10px !important;
    }

    .btn-st p {
        color: #fff !important;
    }

    .services-desciption {
        margin-top: 0px;
    }

    .join-now-img img {
        width: 100%;
        padding: 10px;
    }

    #my-video-projects .title {
        text-align: left;
    }

    #my-video-projects .view-all {
        float: left;
        text-align: left;
    }

    .services-box {
        top: 0px !important;
        padding: 10px 20px;
    }

    section.servives {
        padding: 30px 0;
    }

    .footer-menu {
        text-align: center !important;
    }

    footer .reelapps-img {
        text-align: center;
        margin-top: 20px;
    }

    .integration-box li {
        margin-bottom: 30px;
    }

    #my-connection {
        text-align: center;
    }

    #my-connection .connection-img img {
        margin-bottom: 15px;
    }

    #pills-tabContent.tab-editor {
        position: relative;
    }
    .upgrades-sec-content {
        margin-top: 10px;
    }

    .video-preview{
        padding-bottom: 50px;
    }

    

    .editor-page-pills-box {
        display: inline-block;
    }

    
}

@media only screen and (max-width: 568px) {
    .create-player .btn-next{
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .create-player .btn-next{
        width: 100%;
    }
    .page-tabs.editor-page-pills .nav-link{
        border-right: 0px solid #989898;
    }
    #pills-tab li {
        display: block !important;
        width: 100%;
        text-align: right;
    }
}
@media only screen and (max-width: 450px) {
    .customization-color-picker .color-picker-box-colors .color-pick{
        height: 26px;
        width: 26px;
    }
}

@media only screen and (max-width: 350px) {
    .navbar-light .navbar-brand{
        width: 200px !important;
    }
    .navbar-light .navbar-brand .comp-logo{
        width: 200px !important;
        margin-left: 10px;
    }

    .navbar-light button.navbar-toggler{
        margin-right: 10px;
    }
    .btn-user.dropdown-toggle {
        padding: 0;
    }
    .upgrades-sec-content-box{
        padding: 20px;
    }
}

/** Create Player start */
.create-player {
    min-height: calc(97vh - 118px);
}

.create-player-card {
    background-color: transparent;
    box-shadow: -3px -1px 55px -13px rgba(0, 0, 0, 0.73);
    -webkit-box-shadow: -3px -1px 55px -13px rgba(0, 0, 0, 0.73);
    -moz-box-shadow: -3px -1px 55px -13px rgba(0, 0, 0, 0.73);
    border-radius: 30px;
}

.create-player-card-header {
    background-color: #484848;
    text-transform: uppercase;
    font-weight: 600;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 20px 40px;
    margin-bottom: 0;
}

.create-player-card-body {
    background: #0a0a0a;
    padding: 40px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.create-player-card-body .form-control {
    background: transparent;
    border: 1px solid #fff;
    padding: 15px 20px;
    border-radius: 12px;
}

.create-player-card-body input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #898989;
}

.create-player-card-body input::-moz-placeholder {
    /* Firefox 19+ */
    color: #898989;
}

.create-player-card-body input:-ms-input-placeholder {
    /* IE 10+ */
    color: #898989;
}

.create-player-card-body input:-moz-placeholder {
    /* Firefox 18- */
    color: #898989;
}

.create-player-card-body input:focus {
    background: #000;
    color: #fff;
    /* border-color: #fff; */
    border-color: transparent;
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}


.create-player form {
    width: 900px;
}

.create-player .btn-next {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    border-radius: 15px;
    padding: 20px 30px;
}

.create-player .btn-next i {
    font-size: 20px;
    position: relative;
    top: 2px;
    left: 8px;
}

.create-player .social-checkbox .form-check:last-child {
    margin-right: 0px;
}

.create-player .form-check {
    border: 1px solid #fff;
    border-radius: 15px;
    margin-right: 50px;
    display: block;
    cursor: pointer;
    padding-left: 20px !important;
}

.create-player .form-check:last-child {
    margin-right: 0px;
}

.create-player .form-check-label {
    font-weight: 600;
    padding: 20px;
}

.create-player .form-check-input {
    background-color: transparent;
    border: 2px solid #fff;
    margin-left: 0;
    margin-right: 36px;
    border-radius: 12px;
}

.create-player .form-check:hover {
    border: 1px solid #eb4646;
}

.create-player .form-check:active {
    border: 1px solid #eb4646;
}

.create-player .form-check:focus {
    border: 1px solid #eb4646;
}

/* .pick-video a[data-title]:hover:after{
    content: attr(data-title);
    background: red; 
    position: absolute;
    left: 5%;
    top: 10%;
}

.pick-video a{
    background: pink;
} */

/** Create Player End */

@media only screen and (max-width: 576px) {
    .customization-color-picker p.small {
        text-align: left;
    }
    nav#navbar_top .container {
        padding: 0 10px !important;
    }
    .project-count:before {
        background: none;
    }
    .project-button-search {
        margin-bottom: 15px;
    }

}

@media only screen and (max-width: 425px) {
    h1 {
        font-size: 42px;
    }

    h1 span {
        font-size: 42px;
    }

    .btn-st {
        width: 100%;
    }

    .btn-st .next {
        width: 60px;
        padding: 10px;
    }

    .btn-st .right-arrow {
        top: 16px;
        width: 45px;
    }

    .serv-icon img {
        width: 70px;
    }

    .more-about-services-list p {
        padding: 5px 15px;
    }

    footer .footer-menu li {
        margin-right: 20px;
    }

    .integration-box li {
        width: 100%;
        max-width: 100%;
    }
}

.inner-page {
    background: #171717;
}

.project-button-search input:focus {
    border: none !important;
}

.custom-error {
    font-size: 12px;
    color: #FF3D38;
}

.fa-trash:hover {
    color: #FF6161;
}

/*# sourceMappingURL=style.css.map */


.popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}

.popupVid {
    position: relative;
    background: #FFF;
    padding: 25px;
    width: auto;
    max-width: 400px;
    margin: 0 auto;
}


#featured-video {
    transition: width .2s ease-in-out, height .2s ease-in-out, transform .38s ease-in-out;
}

/** Use .sticky */
#featured-video.is-sticky {
    position: fixed;
    top: 15px;
    left: auto;
    max-width: 280px;
    max-height: 158px;
    width: 280px;
    height: 158px;
}


.vid_container {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
    transition: all 1s ease-out;
    animation: blowUpContent 2s linear;
}

@keyframes blowUpContent {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }

}


/*@keyframes blowUpModal {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}*/


@keyframes fade-in-up {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.video-wrap {
    text-align: center;
}


.stuck {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transform: translateY(100%);
    width: 260px;
    height: 145px;
    animation: fade-in-up .25s ease forwards;
}

.intro {
    position: relative;
    width: 100%;
    background: rgba(100, 100, 100, 0.1);
 }

.popout-video {
    width: 100%;
    position: relative;
}

.popout-video--popout {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    width: 450px;
    max-width: 100%;
    height: auto;
    animation: popin .5s ease-in-out forwards;
}

@keyframes popin {
    to {
        top: 0;
    }
}

.popout-video--popout:hover .popout-video__controls {
    opacity: 1;
}

.popout-video video {
    width: 100%;
    vertical-align: middle;
}

.popout-video__controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: .5rem;
    background: black;
    background: linear-gradient(0deg, transparent 0%, black 100%);
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity .25s ease;
    pointer-events: none;
}

.popout-video__controls .close-video {
    width: 1.77rem;
    height: 1.7rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    border-radius: 50%;
    transition: background .25s ease;
}

.popout-video__controls .close-video:hover {
    background: rgba(50, 50, 50, 0.4);
}

.popout-video__controls .close-video:before, .popout-video__controls .close-video:after {
    content: '';
    position: absolute;
    margin: auto;
    width: 1rem;
    height: 2px;
    background: #fff;
}

.popout-video__controls .close-video:before {
    transform: rotate(45deg);
}

.popout-video__controls .close-video:after {
    transform: rotate(-45deg);
}

.block_loc{
    position: relative;
    padding: 5rem 0px;



}

.block_loc p{
    position: absolute;
     top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,1);
    padding: 10px;
    border-radius: 10px;
}


.hide-table-padding input {
    color: #fff;
}

.hide-table-padding input:focus{
    color: #fff;
}



.dropbox-data{
    text-decoration: none;
}

.logo_size img{
    width: 50px;
    border-radius: 50%;
}

.top-left{
    position: absolute !important;
    top: 0;
    left: 0;
    padding: 10px;
}

.top-right{
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 10px;
}

.bottom-right{
    position: absolute !important;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.bottom-left{
    position: absolute !important;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.top-center{
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.center-left{
    position: absolute !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.center{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

.center-right{
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.bottom-center{
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

#custom_size li a img{
    width: 47px !important;
}

.form-control{

    color: white;
}

.search_thumb{
    color: black !important;
}

.player-box p{
    margin-bottom: 0px;
}
.int_auth{
    color: #fafffc !important;
}


/*.fixed-me{
    margin-bottom: 5px;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0 5px rgb(0 0 0 / 50%);

}*/

.modal{
    z-index: 9999;
}

.pagination .disabled{
    cursor: not-allowed;
}

.text-muted{
    color: #ffff !important;
}

.my_tooltip.drc .tooltiptext {
    left: -50px;
}
.analytics-tables{
    border: 1px solid #777777;
    border-radius: 10px;
/* padding: 10px; */
    margin: 1rem;
}

.input-cover-select{
    display: flex;
    margin: 10px 0;
}